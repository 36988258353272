// Declare a constant enum for colorPalette (Figma) colors
export const enum colorPalette {
  // Main Brand Colors
  strongestAzure = "#272d81",
  strongAzure = "#2d38c8",
  midAzure = "#4b69e6",
  lightAzure = "#9cb5f4",
  lighterAzure = "#dee5fb",

  // Auxiliar Colors
  accentRed = "#EF565F",
  destructiveRed1 = "#DE1A1A",
  destructiveRed2 = "#AD1414",
  destructiveRed3 = "#730D0D",
  accentYellow = "#EDCF66",
  accentGreen1 = "#3DDB69",
  accentGreen2 = "#076105",
  accentBlue1 = "#ECF1FD",
  accentPurple = "#9240B9",
  primaryHoverBlue1 = "#C0D1FF",
  secondaryBlue = "#7F9CEC",
  primaryHoverBlue2 = "#2046AD",
  primaryPressedBlue = "#152E73",
  accentBlue2 = "#215585",
  focusPurple = "#B770FE",

  // Black Tints and Shades
  neutralGray1 = "#F5F5F6",
  disabledGray1 = "#ECECED",
  neutralGray2 = "#D8D8D9",
  disabledGray2 = "#B5B5B6",
  paragraphPlaceholder = "#979899",
  paragraphBaseline = "#474748",
  neutralGray3 = "#323232",
  black = "#111111",

  // MuiRadio Colors
  radioHover = "rgba(181, 181, 182, 0.12)",
  radioFocus = "rgba(181, 181, 182, 0.25)",
}
