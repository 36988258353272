// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aboutus {
  width: 100%;
  padding-block: 4rem;
}
.content-container {
  display: flex;
}
.description-text {
  color: white;
  padding-inline: 1.5rem;
}

.content-container img {
  width: 50%;
}

.content-container-mobile {
  display: block;
}

.content-container-mobile img {
  width: 100%;
}

.description-footer{
  font-size: 1.5rem;
}

p {
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/modules/AboutUs/AboutUs.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".aboutus {\n  width: 100%;\n  padding-block: 4rem;\n}\n.content-container {\n  display: flex;\n}\n.description-text {\n  color: white;\n  padding-inline: 1.5rem;\n}\n\n.content-container img {\n  width: 50%;\n}\n\n.content-container-mobile {\n  display: block;\n}\n\n.content-container-mobile img {\n  width: 100%;\n}\n\n.description-footer{\n  font-size: 1.5rem;\n}\n\np {\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
