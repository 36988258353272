// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: #f8f9fa;
  text-align: center;
  color: #6c757d;
}

.footer-wrapper {
  text-align: center;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.05);
  position: relative;
}

.footer-title {
  font-size: 2rem;
  margin-inline: "auto";
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB","sourcesContent":["footer {\n  background-color: #f8f9fa;\n  text-align: center;\n  color: #6c757d;\n}\n\n.footer-wrapper {\n  text-align: center;\n  padding: 1rem;\n  background: rgba(0, 0, 0, 0.05);\n  position: relative;\n}\n\n.footer-title {\n  font-size: 2rem;\n  margin-inline: \"auto\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
