// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#testimonios{
    padding-inline: 5%;
    padding-bottom: 10%;
    max-width: 1280px;
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/modules/Testimonies/Testimonies.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,YAAY;AAChB","sourcesContent":["#testimonios{\n    padding-inline: 5%;\n    padding-bottom: 10%;\n    max-width: 1280px;\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
