// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#inicio {
  max-width: 1280px; /* o el valor que desees para pantallas extra grandes */
  padding-inline: 15px; /* Ajusta según sea necesario para separación entre el contenido y los bordes del contenedor */ /* Ajusta según sea necesario para separación entre el contenido y los bordes del contenedor */
  margin-inline: auto;
  display: flex;
  margin-top: 80.21px;
}

.img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/modules/Home/Home.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB,EAAE,uDAAuD;EAC1E,oBAAoB,EAAE,8FAA8F,EAAE,8FAA8F;EACpN,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":["#inicio {\n  max-width: 1280px; /* o el valor que desees para pantallas extra grandes */\n  padding-inline: 15px; /* Ajusta según sea necesario para separación entre el contenido y los bordes del contenedor */ /* Ajusta según sea necesario para separación entre el contenido y los bordes del contenedor */\n  margin-inline: auto;\n  display: flex;\n  margin-top: 80.21px;\n}\n\n.img-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
