// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:regular,bold,italic&subset=latin,latin-ext);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: "Roboto Mono", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px; /* Ajusta según sea necesario para compensar el espacio negativo de los márgenes */
  margin-left: -15px; /* Ajusta según sea necesario para compensar el espacio negativo de los márgenes */
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding-right: 15px; /* Ajusta según sea necesario para separación entre columnas */
  padding-left: 15px; /* Ajusta según sea necesario para separación entre columnas */
}

.container {
  width: 100%;
  padding-right: 15px; /* Ajusta según sea necesario para separación entre el contenido y los bordes del contenedor */
  padding-left: 15px; /* Ajusta según sea necesario para separación entre el contenido y los bordes del contenedor */
  margin-right: auto;
  margin-left: auto;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;AACA;EACE,SAAS;EACT;8CAC4C;EAC5C,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB,EAAE,kFAAkF;EACvG,kBAAkB,EAAE,kFAAkF;AACxG;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,eAAe;EACf,mBAAmB,EAAE,8DAA8D;EACnF,kBAAkB,EAAE,8DAA8D;AACpF;;AAEA;EACE,WAAW;EACX,mBAAmB,EAAE,8FAA8F;EACnH,kBAAkB,EAAE,8FAA8F;EAClH,kBAAkB;EAClB,iBAAiB;AACnB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Roboto+Mono:regular,bold,italic&subset=latin,latin-ext\");\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\nbody {\n  margin: 0;\n  font-family: \"Roboto Mono\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\",\n    \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n.row {\n  display: flex;\n  flex-wrap: wrap;\n  margin-right: -15px; /* Ajusta según sea necesario para compensar el espacio negativo de los márgenes */\n  margin-left: -15px; /* Ajusta según sea necesario para compensar el espacio negativo de los márgenes */\n}\n\n.col {\n  flex-basis: 0;\n  flex-grow: 1;\n  max-width: 100%;\n  padding-right: 15px; /* Ajusta según sea necesario para separación entre columnas */\n  padding-left: 15px; /* Ajusta según sea necesario para separación entre columnas */\n}\n\n.container {\n  width: 100%;\n  padding-right: 15px; /* Ajusta según sea necesario para separación entre el contenido y los bordes del contenedor */\n  padding-left: 15px; /* Ajusta según sea necesario para separación entre el contenido y los bordes del contenedor */\n  margin-right: auto;\n  margin-left: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
